<template>
  <a-dialog-form :value="value" @input="close()">
    <v-card>
      <v-card-title>Импорт данных AV100</v-card-title>
      <v-card-text>
        <v-text-field v-model="data.from" label="Дата от" />
        <v-text-field v-model="data.to" label="Дата до" />
        <v-text-field v-model="data.campaign" label="Кампания" />
      </v-card-text>
      <v-card-actions>
        <a-btn-form-close @click="close()" />
        <a-btn-form-save @click="submit()">Импорт</a-btn-form-save>
      </v-card-actions>
    </v-card>
  </a-dialog-form>
</template>

<script>
export default {
  props: {
    value: Boolean,
  },
  data() {
    return {
      loading: false,
      data: {
        from: "",
        to: "",
        campaign: "",
      },
    };
  },
  created() {
    this.init();
  },
  watch: {
    value() {
      this.init();
    },
  },
  methods: {
    init() {
      this.loading = false;
    },
    close() {
      this.$emit("input");
    },
    async submit() {
      this.loading = true;
      try {
        let res = await this.$axios.p(
          this.$root.api.cardItemPromoImportAV100,
          this.data
        );
        this.$root.$emit("show-info", {
          text: "Импорт выпонен",
        });
        this.close();
      } catch (error) {
        this.$root.$emit("show-info", {
          text: "Ошибка " + error,
        });
      }
      this.loading = false;
    },
  },
};
</script>